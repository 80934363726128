import React from 'react'
import PropTypes from 'prop-types'
import LoaderIcon from '../icons/LoaderIcon'
import '../../styles/components/global/LoadingSpinner.scss';

const LoadingSpinner = ({ height, text, style }) => {
  const loadingMessage = text !== null && text !== undefined ? text : 'Loading';

  if (style === 'chasing') {
    const sizeProps = height ? { style: {width: height} } : {};

    return (
      <div className='loading-spinner-component chasing-spinner'>
        <svg className='chasing-icon' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' { ...sizeProps }>
          <circle cx='50' cy='50' r='45'/>
        </svg>
        { loadingMessage && <span className='loading-msg'>{ text }</span> }
      </div>
    );
  }

  return (
    <div className='loading-spinner-component'>
      <LoaderIcon height={ height || '' }/>
      { loadingMessage && <span className='loading-msg'>{ text }</span> }
    </div>
  );
}

LoadingSpinner.propTypes = {
  height: PropTypes.string,
  text:   PropTypes.string,
  style:  PropTypes.string
};

LoadingSpinner.defaultProps = {
  text:  'Loading',
  style: 'classic'
};

export default LoadingSpinner
